.nav .open > a, 
.nav .open > a:hover, 
.nav .open > a:focus {background-color: transparent;}

#sidebar-wrapper {
    z-index: 1000;
    left: 220px;
    width: 0;
    height: 100%;
    margin-left: -220px;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--fade-black);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-size: 1.25rem;
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

#wrapper.toggled #sidebar-wrapper {
    width: 220px;
}

.navbar {
    padding: 0;
  }
  
  .sidebar-nav {
      position: absolute;
      top: 0;
      width: 220px;
      margin: 0;
      padding: 0;
      list-style: none;
  }
  
  .sidebar-nav li {
      position: relative; 
      line-height: 20px;
      display: inline-block;
      width: 100%;
      font-size: 1.2rem;
  }
  
  .sidebar-nav li:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      height: 100%;
      width: 3px;
      background-color: var(--fade-black);
      -webkit-transition: width .2s ease-in;
        -moz-transition:  width .2s ease-in;
         -ms-transition:  width .2s ease-in;
              transition: width .2s ease-in;
  
  }
  .sidebar-nav li:first-child a, .sidebar-nav li:first-child button {
      color: #fff;
  }
  .sidebar-nav .dropdown-menu li:before {
      background-color: var(--flex-pink);   
  }

  .sidebar-nav li:hover:before,
  .sidebar-nav li.open:hover:before {
      width: 100%;
      -webkit-transition: width .2s ease-in;
        -moz-transition:  width .2s ease-in;
         -ms-transition:  width .2s ease-in;
              transition: width .2s ease-in;
  
  }
  
  .sidebar-nav li a, .sidebar-nav li button {
      display: block;
      color: #ddd;
      text-decoration: none;
      padding: 10px 15px 10px 30px;    
      font-family: 'Lato';
  }
  
  .sidebar-nav li a:hover,
  .sidebar-nav li a:active,
  .sidebar-nav li a:focus,
  .sidebar-nav li.open a:hover,
  .sidebar-nav li.open a:active,
  .sidebar-nav li.open a:focus,
  .sidebar-nav li button:hover,
  .sidebar-nav li button:active,
  .sidebar-nav li button:focus,
  .sidebar-nav li.open button:hover,
  .sidebar-nav li.open button:active,
  .sidebar-nav li.open button:focus{
      color: #fff;
      text-decoration: none;
      background-color: transparent;
  }
  .sidebar-header {
      position: relative;
      width: 100%;
      display: inline-block;
  }
  .sidebar-brand {
      position: relative;
      background:  var(--fade-black);
     padding-top: 1em;
  }
  .sidebar-brand a {
      color: #ddd;
  }
  .sidebar-brand a:hover {
      color: #fff;
      text-decoration: none;
  }
  .sidebar-nav .dropdown-menu {
      position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
      border: none;
      background-color: var(--fade-black);
      box-shadow: none;
  }
  .dropdown-menu.show {
      top: 0;
  }
  
  .hamburger {
    position: fixed;
    top: 20px;  
    z-index: 999;
    display: block;
    width: 32px;
    height: 32px;
    margin-left: 15px;
    background: transparent;
    border: none;
  }
  .hamburger:hover,
  .hamburger:focus,
  .hamburger:active {
    outline: none;
  }
  .hamburger.is-closed:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed:hover:before {
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(-100px,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  
  .hamburger.is-closed .hamb-top,
  .hamburger.is-closed .hamb-middle,
  .hamburger.is-closed .hamb-bottom,
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-middle,
  .hamburger.is-open .hamb-bottom {
    position: absolute;
    left: 0;
    height: 4px;
    width: 100%;
  }
  .hamburger.is-closed .hamb-top,
  .hamburger.is-closed .hamb-middle,
  .hamburger.is-closed .hamb-bottom {
    background-color: var(--fade-black);
  }
  .hamburger.is-closed .hamb-top { 
    top: 5px; 
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed .hamb-middle {
    top: 50%;
    margin-top: -2px;
  }
  .hamburger.is-closed .hamb-bottom {
    bottom: 5px;  
    -webkit-transition: all .35s ease-in-out;
  }
  
  .hamburger.is-closed:hover .hamb-top {
    top: 0;
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-closed:hover .hamb-bottom {
    bottom: 0;
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-middle,
  .hamburger.is-open .hamb-bottom {
    background-color: var(--fade-black);
  }
  .hamburger.is-open .hamb-top,
  .hamburger.is-open .hamb-bottom {
    top: 50%;
    margin-top: -2px;  
  }
  .hamburger.is-open .hamb-top { 
    -webkit-transform: rotate(45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
  }
  .hamburger.is-open .hamb-middle { display: none; }
  .hamburger.is-open .hamb-bottom {
    -webkit-transform: rotate(-45deg);
    -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
  }
  .hamburger.is-open:before {
    content: '';
    display: block;
    width: 100px;
    font-size: 14px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    opacity: 0;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  .hamburger.is-open:hover:before {
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(-100px,0,0);
    -webkit-transition: all .35s ease-in-out;
  }
  
  .overlay {
      position: fixed;
      display: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(250,250,250,.8);
      z-index: 1;
  }

  .brand-logo {
    width: 75%;
  }

  .sidebar-nav>li:first-of-type {
    margin-top: 1.5rem !important;
  }
  .dropdown-menu[data-bs-popper] {
    top: 0 !important;
  }
  

  .sidebar-nav .dropdown-menu > li > a, .sidebar-nav .dropdown-menu > li > button {
    margin-left: 0.75rem;
  }

  .sidebar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bg-flex-pink {
    background-color: var(--flex-pink);
  }

  .sidebar-nav button {
    background-color: transparent;
    border: none;
    width: calc(100% - 0.75rem);
    text-align: start;
  }