:root {
  --deep-purple: #340a3a;
  --deep-red: #9f0c18;
  --fade-black: #2e2e31;
  --flex-pink: #e20075;
  --toastify-color-dark: #2e2e31;
}

code {
  font-family: 'Lato';
}

.bg-fade-black {
  background-color: var(--fade-black) !important;
}

