.login-form {
    border-radius: 10px;
    background-color: var(--fade-black);
    width: 100%;
}

.login-form .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-form .brand-img {
    width: 50%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.login-wrapper {
    min-height: calc(100vh - 50px);
}

.login-col {
    align-self: center;
}