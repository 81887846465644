.btn-red {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: var(--deep-red) !important;
    --bs-btn-border-color: var(--deep-red) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #8f151f;
    --bs-btn-hover-border-color: #8f151f !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #8f151f;
    --bs-btn-active-border-color: #8f151f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--deep-red);
    --bs-btn-disabled-border-color: #8f151f;
}

.btn-purple {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: var(--deep-purple) !important;
    --bs-btn-border-color: var(--deep-purple) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4e1156;
    --bs-btn-hover-border-color: #4e1156 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4e1156;
    --bs-btn-active-border-color: #4e1156;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--deep-purple);
    --bs-btn-disabled-border-color: #4e1156;
}

.btn-fadeblack {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: var(--fade-black) !important;
    --bs-btn-border-color: var(--fade-black) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3d3d41;
    --bs-btn-hover-border-color: #3d3d41 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3d3d41;
    --bs-btn-active-border-color: #3d3d41;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--fade-black);
    --bs-btn-disabled-border-color: #3d3d41;   
}

.btn-flexpink {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: var(--flex-pink) !important;
    --bs-btn-border-color: var(--flex-pink) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d50570;
    --bs-btn-hover-border-color: #d50570 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #d50570;
    --bs-btn-active-border-color: #d50570;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--flex-pink);
    --bs-btn-disabled-border-color: #d50570;
}