body {
  position: relative;
  overflow-x: hidden;
  background-color: #CFD8DC;
}
body,
html { height: 100%;}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 220px;
}

#page-content-wrapper {
  width: 100%;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  width: calc(100% - 220px);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#page-content-wrapper {
  min-height: 100%;
}

.app-container {
  min-height: calc(100vh - 50px);
}